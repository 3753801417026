import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { Button, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Publications() {

    const eventData = [
        {
            id: 1,
            title: "IIISM - AYURCON2022",
            subtitle: "AYUSH-27: Machine Learning Driven Accurate Assessment of Ayurveda Prakriti and Vikriti Using Conversational AI and Digital Nadi Pariksha",
            tags: ["Artificial Intelligence", "Machine Learning", "Deep Neural Network", "Prakriti", "Vikriti"],
            date: "2022-09-12",
            link: "https://www.ayurai.io/Publications/IIISM%20-%20AYURCON2022.html"
        },

        {
            id: 2,
            title: "IIISM - AYURCON2021",
            subtitle: "AYUSH-28: A Study on The Therapeutic Efficacy of Ayurveda Treatment Quantified Based on Biochemical Markers, Immune Markers, And Symptom Reduction",
            tags: ["AI", "Health", "Technology"],
            date: "2022-09-05",
            link: "https://www.ayurai.io/Publications/AYUSH28.html"
        },

        {
            id: 3,
            title: "Effect of Ayurveda Treatment on Inflammatory Marker Levels",
            subtitle: "The purpose of this study was to quantify the effectiveness of personalized Ayurveda-based treatment by examining the changes in cytokine levels for various non-communicable diseases such as diabetes, arthritis, GERD, etc.",
            tags: ["AI", "Health", "Technology"],
            date: "2022-09-05",
            link: "https://www.ayurai.io/Publications/Effect%20of%20Ayurveda%20Treatment%20on%20Inflammatory%20Marker%20Levels.html"
        },

        {
            id: 4,
            title: "Ayurveda-Data Science and Omics",
            subtitle: "Machine and Deep learning driven accurate assessment of Ayurveda Prakriti and Vikriti using Conversational AI and Digital Nadi Pariksha",
            tags: ["Artificial Intelligence", "Machine Learning", "Deep Neural Network", "Prakriti", "Vikriti"],
            date: "2022-12-09",
            link: "https://www.ayurai.io/Publications/Ayurveda-Data%20Science%20and%20Omics.html"
        },
    ];


    const sortedData = eventData.sort((a, b) => new Date(b.date) - new Date(a.date));

    return <section className='' id='events'>
        <div className='Events-section' style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <p className='mt-15 text-center'>Our Publications</p>
            <div className="card-container" >

                <div className="expanded-card">
                    <h2>{sortedData[0]?.title}</h2>
                    <div>
                        <p>{sortedData[0]?.subtitle}</p>

                        <div className="tags">
                            {sortedData[0]?.tags.map((tag, index) => (
                                <span key={index} className="tag">{tag}</span>
                            ))}
                        </div>

                        <div className='flex flex-row items-center justify-between pub-action-btn'>

                            <p className="date">{sortedData[0]?.date}</p>

                            <Button sx={{
                                background: "#483934",
                                color: "#BED040"
                            }}
                                onClick={() => {
                                    window.open(sortedData[0]?.link)
                                }}>
                                <EastIcon />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="collapsed-cards">
                    {sortedData.slice(1).map((item) => (
                        <div key={item.id} className="collapsed-card">
                            <div>
                                <h3>{item?.title}</h3>
                                <IconButton onClick={() => {
                                    window.open(item?.link)
                                }}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </div>
                            <p className='mt-5'>{item?.subtitle}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>

    </section>
}

export default Publications